import React from "react"
import ReactPlayer from "react-player"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const VideoLeifMohr = () => {
  const lang = "fo"

  return (
    <Layout lang={lang}>
      <SEO title="Høgra - Video" />
      <section className="video-page">
        <div className="container">
          <div className="embed-container">
            <ReactPlayer
              url="https://vimeo.com/816001014"
              width="100%"
              heigth="100%"
              playing={true}
              controls={true}
              muted={true}
              loop={true}
              playsinline
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default VideoLeifMohr
